import { FC, useState } from 'react';
import styled from 'styled-components';
import StyledButton from '../StyledButton';
import PageContainer from '../PageContainer';
import Spinner from '../Spinner';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import ContactFormTextInput from '../ContactFormTextInput';
import ContactFormInputLabel from '../ContactFormInputLabel';
import ContactFormCheckbox from '../ContactFormCheckbox';
import { useTranslation } from 'react-i18next';
import SubmissionConfirmation from '../SubmissionConfirmation';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
    width: fit-content;
    padding: 2em 1.33em;
    border-radius: 2em;
    @media (min-width: 768px){
        padding: 2.33em;  
    }
`;
const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    min-width: 450px;
    max-width: 450px;
    @media (max-width: 768px){
        min-width: 100%;
        max-width: 100%;
        padding: 0;    
    }
`;
const ButtonSection = styled.section`
    padding: 1em 0em 0.66em 0em;
    width: 100%;
`;
const StyledFormHeading = styled.h1`
    width: 100%;
    color: ${props => props.theme.white};
    padding: 0em 0em 0.5em 0em;
    font-size: 2.33em;
`;
const StyledFormSubheading = styled.p`
    width: 100%;
    color: ${props => props.theme.white};
`;
// const CalendlyWidget = styled.div`
//     min-width: 320px;
//     height: 630px;
// `;

interface ContactFormData{
    name: string;
    email: string;
    description: string;
}
const ContactForm: FC = () => {
    const {t} = useTranslation();
    const [contactFormData, setContactFormData] = useState<ContactFormData | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);
    const gaEventTracker = useAnalyticsEventTracker("ContactForm", "submit", "Contact Form Submitted");

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);
        const lambdaURL = process.env.REACT_APP_CONTACT_LAMBDA_URL;
        fetch(`${lambdaURL}`, {
            method: "POST",
            body: JSON.stringify(contactFormData)
        })
        .then(res => {
            console.log(res);
            if(res.status === 200){
                setIsSubmitting(false);
                setHasBeenSubmitted(true);                
            }
        })
        .catch(error => {
            console.log(error);
            setIsSubmitting(false);
        });
    };

    const handleFormInputChange = (e: any) => {
        let formData: any = contactFormData === null ? 
            {name: "", email: "", services: []} :
            contactFormData;

        const inputValue = e.target.value;
        const inputName = e.target.name;
        if(inputName === "services"){
            if(formData[inputName].includes(inputValue)){
                formData[inputName] = formData[inputName].filter((service:string) => service !== inputValue)
            }else{
                formData[inputName].push(inputValue)
            }
        }else{ formData[inputName] = inputValue }
        setContactFormData(formData);
    }
    //@ts-ignore
    // Calendly.initInlineWidget({
    //     url: 'https://calendly.com/tudorjohnco/free-consultation?background_color=blue',
    //     parentElement: document.getElementById('calendly-widget'),
    //     // prefill: {
    //     //     name,
    //     //     email
    //     // }         
    // });

    return (
        <PageContainer>
            <Container>
                {/* <div>
                    <h1>Start today with a consultation</h1>
                </div> */}
                <StyledForm onSubmit={(e) => { handleFormSubmit(e); gaEventTracker(); }}>
                    {hasBeenSubmitted ? 
                    <SubmissionConfirmation/>  
                    :
                    <>
                        {/* <CalendlyWidget id="calendly-widget"></CalendlyWidget>  */}

                        <StyledFormHeading>{t("getStartedFormHeading")}</StyledFormHeading>
                        <StyledFormSubheading>{t("getStartedFormSubheading")}</StyledFormSubheading>
                        <ContactFormTextInput
                            label={t('name')}
                            name="name"
                            changeHandler={(e)=>handleFormInputChange(e)}
                        />
                        <ContactFormTextInput
                            label={t('email')}
                            name="email"
                            changeHandler={(e)=>handleFormInputChange(e)}
                        />

                        <ContactFormInputLabel>{t("selectOneOrMore")}</ContactFormInputLabel>
                        <ContactFormCheckbox
                            name="services" 
                            type="checkbox" 
                            value="Software Development"
                            changeHandler={(e)=>handleFormInputChange(e)}
                            label="Software Development"
                        />
                        <ContactFormCheckbox
                            name="services" 
                            type="checkbox" 
                            value="Web Development"
                            changeHandler={(e)=>handleFormInputChange(e)}
                            label="Web Development"
                        />
                        <ContactFormCheckbox
                            name="services" 
                            type="checkbox" 
                            value="Blockchain Development"
                            changeHandler={(e)=>handleFormInputChange(e)}
                            label="Blockchain Development"
                        />
                        <ContactFormCheckbox
                            name="services" 
                            type="checkbox" 
                            value="Consulting"
                            changeHandler={(e)=>handleFormInputChange(e)}
                            label={t("consulting")}
                        />
                        <ButtonSection>
                            <StyledButton
                                variant='solid'
                                label={isSubmitting ? <Spinner /> : t('submit')}
                                type="submit"
                                disabled={isSubmitting}
                            />
                        </ButtonSection>
                    </>}
                </StyledForm>
            </Container>
        </PageContainer>
    )
}
export default ContactForm;
