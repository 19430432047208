import { FC } from "react";
import styled from "styled-components";
import usePageTitle from "../../hooks/usePageTitle";
import usePageDesciption from "../../hooks/usePageDescription";
import { useTranslation } from "react-i18next";
import GetStartedCTA from "../../Components/GetStartedCTA"
import ScrollableList from "../../Components/ScollableList";
import { WEB_HELP_LIST } from "../../shared/constants";
import WebTechnologiesCarousel from "../../Components/WebTechnologiesCarousel";

const StyledHeading = styled.h1`
    width: 100%;
    overflow-wrap: break-word;
    padding-bottom: 0.33em;
`;
const StyledSubheading = styled.p`
    font-size: 1.33em;
    // line-height: 1.8rem;
    font-weight: 600;
    color: #aaa;
`;
const HeroContainer = styled.div`
    max-height: 80vh;
    min-height: 66vh;
    overflow: hidden;
    padding: 2em 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    position: relative;  
`;

const WebDevelopment: FC = () => {
    const {t} = useTranslation();
    usePageTitle(`Web Development Service`);
    usePageDesciption("Get a website or web application developed. Take your digital strategy to the next level and reach millions of users with scalable and custom web solutions.");
    return(
        <>
            <HeroContainer>
                <StyledHeading>
                    {t("webPageHeading")}
                </StyledHeading>
                <StyledSubheading>Pixel-perfect UIs, delightful user experiences, and performant systems are our forte. We build websites and web applications with state-of-the-art technologies that can scale with your business.</StyledSubheading>
            </HeroContainer>
            <ScrollableList
                list={WEB_HELP_LIST}
            />
            <WebTechnologiesCarousel/>
            <GetStartedCTA />
        </>
    )
}

export default WebDevelopment;