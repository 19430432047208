import { FC } from "react";
import PageContainer from "../../Components/PageContainer";

const NotFound: FC = () =>{
    return(
        <PageContainer>
            <h1>404: Page not found</h1>
        </PageContainer>
    )
}
export default NotFound;