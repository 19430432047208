import { FC } from "react"
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

const StyledLink = styled(Link)`
    width: 100%;
    border: solid 2px transparent;
    margin-bottom: 1em;
    @media screen and (min-width:768px){
        display: flex;
        margin-bottom: 0.66em;
        &:hover{
            border: solid 2px blue;
            border-radius: 0.25em;
        }
    }
`;
const NavIconContainer = styled.div`
    @media screen and (min-width: 768px){
        width: 25%;
    }
`;
const NavIcon = styled(FontAwesomeIcon)`
    @media screen and (max-width: 768px){
        margin-bottom: 0.33em;
    }
    font-size: 1.33em;
    color: white;
    @media screen and (min-width: 768px){
        color: #333;
        padding: 0.66em;
        float: left;
    }
`;
const NavItemLabel = styled.p`
    font-weight: 600;
    color: white;
    @media screen and (min-width: 768px){
        color: #333;
    }
`;
const NavItemDescription = styled.p`
    overflow-wrap: break-word;
    font-weight: 400;
    padding-right: 0.66em;
    color: #ccc;
    line-height: 1.33em;
    letter-spacing: -0.033em;
    @media screen and (min-width: 768px){
        color: #333;
        font-size: 0.9em;
    }
`;
const NavItemContent = styled.div`
    background-color: black;
    min-height: 100%;
    padding: 0.66em 0em;
    @media screen and (min-width: 768px){
        background-color: unset;
        width: 85%;
    }
`;

interface NavItemChildProps{
    navItem: any;
    toggleDropdown: ()=>void;
    toggleMenu?: ()=>void;
}

const NavItemChild: FC<NavItemChildProps> = ({navItem, toggleDropdown, toggleMenu}) => {
    const {t} = useTranslation();

    const handleToggle = () => {
        toggleDropdown();
        if(toggleMenu !== undefined){
            toggleMenu();
        }
    }
    return(
        <StyledLink to={navItem.path} onClick={handleToggle}>
            <NavIconContainer>
                <NavIcon icon={navItem.icon} />                                   
            </NavIconContainer>
            <NavItemContent>     
                <NavItemLabel>{t(navItem.label)}</NavItemLabel>
                <NavItemDescription>{t(navItem.descriptionTranslationString)}</NavItemDescription>
            </NavItemContent>
        </StyledLink>
    )
}
export default NavItemChild
