import { FC } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import DesktopNav from '../DesktopNav';
import { TUDORJOHN_LOGO } from '../../shared/constants';
import { useTranslation } from 'react-i18next';
import { GetStartedLink } from '../GetStartedCTA';

const HeaderCTA = styled(GetStartedLink)`
    @media (max-width: 768px){
        display: none;    
    }
`;
const StyledHeader = styled.header`
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: var(--header-height);
    background: ${props => props.theme.black};
    border-bottom: solid 1px #333;
    overflow: visible;
    position: relative;
`;
const HeaderContentStart = styled.div`
    position: absolute;
    left: 0;
    padding-left: 7vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
`;
const HeaderContentEnd = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 7vw;
`;
const Logo = styled.img`
    margin-right: 2em;
    max-width: 175px;
`;
const StyledLink = styled(Link)`
    text-decoration: none;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${({theme}) => theme.gray};
    font-size: 1.2em;
    cursor: pointer;
`;
const MenuToggle = styled.button`
    @media (max-width: 768px){
        margin-left: 1.33rem;    
    }
    @media (min-width: 768px){
        display: none;
    }
    border: none;
    outline: none;
    background: transparent;
    margin-left: 1.66rem;

    &:focus{
        outline: solid 2px white;
    }
`;
const HeaderLanguageButton = styled.button`
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    margin-left: 1em;
`;
const HeaderLanguage = styled.p`
    color: ${({theme}) => theme.white};
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 700;
    margin-right: 0.25rem;
`;

interface HeaderProps{
    toggleMobileMenu: () => void;
    toggleLangDialog: () => void;
    toggleServicesDropdown: () => void;
    toggleCompanyDropdown: () => void;
    selectedLang: string | null;
    isLangDialogOpen: boolean;
    isServicesDropdownOpen: boolean;
    isCompanyDropdownOpen: boolean;
}
const Header: FC<HeaderProps> = ({
    toggleServicesDropdown, 
    toggleCompanyDropdown, 
    toggleMobileMenu, 
    toggleLangDialog, 
    selectedLang, 
    isLangDialogOpen,
    isServicesDropdownOpen,
    isCompanyDropdownOpen
}) => {
    const {t} = useTranslation();

    return (
        <StyledHeader data-cy="header">
            <HeaderContentStart>
                <StyledLink to="/">
                    <Logo alt='Tudor John & Co. logo' src={TUDORJOHN_LOGO}/>
                </StyledLink>
                <DesktopNav 
                    toggleServicesDropdown={toggleServicesDropdown}
                    isServicesDropdownOpen={isServicesDropdownOpen} 
                    toggleCompanyDropdown={toggleCompanyDropdown}
                    isCompanyDropdownOpen={isCompanyDropdownOpen} 
                />
            </HeaderContentStart>
            <HeaderContentEnd>
                <HeaderCTA
                    to="/get-started"
                >
                    {t('getStarted')}
                </HeaderCTA>
                <HeaderLanguageButton type="button" onMouseEnter={toggleLangDialog} onClick={toggleLangDialog}>
                    <HeaderLanguage>{selectedLang || i18n.language}</HeaderLanguage>
                    <StyledFontAwesomeIcon icon={faCaretDown} />
                </HeaderLanguageButton>
                <MenuToggle type="button" aria-label="Menu" onClick={toggleMobileMenu}>
                    <StyledFontAwesomeIcon icon={faBars}/>
                </MenuToggle>
            </HeaderContentEnd>
        </StyledHeader>
    );
};

export default Header;