interface Languages{  
    label: string;
    value: string;
}

export const languages: Array<Languages> = [
    {
        label: "English",
        value: "en",
    },
    {
        label: "Espanol",
        value: "es",
    },
    {
        label: "Deutsch",
        value: "de",
    },
];