import { FC } from 'react';
import styled from 'styled-components';
import { generateFooterCopyright } from '../../shared/functions';
import { NAVIGATION } from '../../shared/constants';
import { Link } from 'react-router-dom';

const StyledFooter = styled.footer`
    padding: 0vh 7vw;
    overflow: hidden;
`;
const FooterNavContainer = styled.nav`
    display: inline-flex;
    padding: 4em 0em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 4.8rem;
    margin: 4.8rem 0rem auto;
    @media screen and (min-width: 768px){
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`;
const FooterNavSection = styled.section`
    width: 100%
`;
const FooterNavSectionLabel = styled.h5`
    color: #888;
    margin-bottom: 2em;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 500;
`;
const FooterNavSectionList = styled.ul`
    color: white;
    list-style: none;
    width: 100%;
`;
const FooterNavListItem = styled.li`
    margin-bottom: 1em;
`
const FooterNavLink = styled(Link)`
    color: #ccc;
    font-weight: 500;
`;

const FooterContentStart = styled.div`
    display: inline-flex;
    position: absolute;
    left: 7vw;
`;

const FooterText = styled.p`
    color: ${({theme}) => theme.gray};
    font-size: 0.75rem;
`;
const FooterCopyright = styled.div`
    display: flex;
    align-items: center;
    max-width: 100vw;
    width: 100vw;
    height: 60px;
    background-color: ${({theme}) => theme.black};
    font-size: 0.9rem;
    color: ${({theme}) => theme.white}
`;

const Footer: FC = () => {
    return (
        <StyledFooter data-cy="footer">
            <FooterNavContainer>
                {
                    NAVIGATION.map((CATEGORY: any, i: number) => 
                    <FooterNavSection key={i}>
                        <FooterNavSectionLabel>{CATEGORY.LABEL}</FooterNavSectionLabel> 
                        <FooterNavSectionList>
                            {CATEGORY.CHILDREN.map((navItem: any, i: number)=>
                                <FooterNavListItem key={i}>
                                    <FooterNavLink to={navItem.path}>{navItem.label}</FooterNavLink>
                                </FooterNavListItem>
                            )}
                        </FooterNavSectionList>
                    </FooterNavSection>
                    )
                }
                <FooterNavSection>

                </FooterNavSection>
            </FooterNavContainer>
            <FooterCopyright>
                <FooterContentStart>
                    <FooterText>{generateFooterCopyright()}</FooterText>
                </FooterContentStart>
            </FooterCopyright>
        </StyledFooter>
    );
};

export default Footer;