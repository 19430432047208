import { FC } from 'react';
import styled from 'styled-components';
import ContactFormInputLabel from '../ContactFormInputLabel';

const StyledTextInput = styled.input`
    width: 100%;
    padding: 0.8em 0.66em;
    background-color: #202020;
    border: solid 1px #c3c3c3;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    &:-internal-autofill-selected{
        background-color: transparent;
    }
    &:focus{
        border-color: transparent;
    }
`;
const StyledSection = styled.section`
    padding: 0em 0em 0.66em 0em;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

interface ContactFormTextInputProps{
    label: string;
    name: string;
    changeHandler: (e: any) => void;
}

const ContactFormTextInput: FC<ContactFormTextInputProps> = ({ label, name, changeHandler}) => {
 
    return (
        <StyledSection>
            <ContactFormInputLabel>{label}</ContactFormInputLabel>
            <StyledTextInput
                required 
                name={name}
                type="text"
                onChange={changeHandler}
            ></StyledTextInput>
        </StyledSection>          
    )
}
export default ContactFormTextInput;