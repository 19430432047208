import { FC, ReactElement } from 'react';
import styled from 'styled-components';

interface StyledButtonProps{
    padding?: string;
    color?: string;
    backgroundColor?: string;
    variant?: string;
    disabled: boolean;
    type: "button" | "submit" | "reset";
};

interface StyledButtonComponentProps extends StyledButtonProps{
    clickHandler?: (e: any) => void;
    label: any;
};

const Button = styled.button<StyledButtonProps>`
    @media (max-width: 768px){
        padding: ${({padding}) => padding || '1em 1em'};
   
        margin: 1em 0em;
    }
    color: ${({variant, color, theme: {white, black}}) => 
        variant === 'outlined' ? white :
        color !== undefined ? color :
        black
    };     

    background-color: ${({variant, backgroundColor, theme: {black, white}}) => 
        variant === 'outlined' ? 'transparent' :
        backgroundColor !== undefined ? backgroundColor :
        white
    };

    border: solid 2px ${({variant, color, backgroundColor, theme: {white}}) => 
        variant === 'outlined' && color !== undefined ? color :
        backgroundColor !== undefined ? backgroundColor :
        white
    }; 
    border: none;
    width: 100%;
    justify-content: center;
    padding: 1em 2em;
    letter-spacing: -0.033em;
    display: flex;
    text-align: center;
    &:active{
        color: ${({color, theme: {black}}) =>
            color !== undefined ? color :
            black
        };     

        background-color: ${({backgroundColor, theme: {white}}) => 
            backgroundColor !== undefined ? backgroundColor :
            white
        };

        border-color: ${({variant, color, backgroundColor, theme: {white}}) => 
            variant === 'outlined' && backgroundColor !== undefined ? backgroundColor :
            white
        };
    }
`;
const ButtonContainer = styled.div`
    @media (max-width: 768px){
        width: 100%;
    }
    width: 100%;
    display: flex;
`;

const StyledButton: FC<StyledButtonComponentProps> = ({
    label, clickHandler, color, padding, backgroundColor, variant, disabled, type }): ReactElement => {

    return (
        <ButtonContainer>
            <Button 
                variant={variant}
                padding={padding} 
                onClick={clickHandler}
                color={color}
                backgroundColor={backgroundColor}
                disabled={disabled}
                type={type}
            >{label}</Button>
        </ButtonContainer>
    );
};

export default StyledButton;