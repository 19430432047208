import styled from 'styled-components';
import { Overlay } from '../Overlay';
import { languages } from '../../localization/languages';
import i18n from 'i18next';
import { FC } from 'react';
import { SELECTED_LANG_KEY } from '../../shared/constants';

const DialogContainer = styled.div`
    position: absolute;
    top: var(--header-height);
    left: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Dialog = styled.div`
    background-color: #eee;
    z-index: 101;
    padding: 1em;
    position: absolute;
    right: calc(7vw + 1.33em);
    top: 0;
`;
const LanguageOption = styled.button`
    @media (max-width: 768px){
        font-size: 0.85em; 
    }
    background: transparent;
    color: black;
    padding: 0.66em 1em;
    font-weight: 600;
    margin: 0.33em;
    border: 2px solid transparent;
    outline: none;
    &:hover{
        border: solid 2px blue;
        border-radius: 0.25em;
    }
`;
const LanguageOptionSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    float: left;
`;

interface LanguageDialogProps{
    toggleLangDialog: () => void;
    open: boolean;
    toggleServicesDropdown: () => void;
    toggleCompanyDropdown: () => void;
}

const LanguageDialog: FC<LanguageDialogProps> = ({toggleLangDialog, open}) => {

    const handleLangChange = (lang: string) => {
        localStorage.setItem(SELECTED_LANG_KEY, lang);
        i18n.changeLanguage(lang);
        toggleLangDialog();
    };

    return (
        <>
            <DialogContainer onMouseLeave={toggleLangDialog}>
                <Dialog>
                    <LanguageOptionSection>
                        {languages.map((lang: any) => 
                            <LanguageOption 
                                key={lang.value} 
                                onClick={() => handleLangChange(lang.value)}
                            >{lang.label}</LanguageOption>
                        )}
                    </LanguageOptionSection>
                </Dialog>
            </DialogContainer>
            <Overlay open={open} onClick={toggleLangDialog}/>
        </>
    )
}

export default LanguageDialog;