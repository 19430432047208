import { FC } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const ConfirmationContainer = styled.div`
`;
const ConfirmationHeading = styled.h1`
    font-size: 1.66em;
    white-space: nowrap;
`;
const HeadingContainer = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: limegreen;
    margin-right: 0.33em;
    font-size: 2.33em;
`;
const StyledParagraph = styled.p`
`;

const SubmissionConfirmation: FC = () => {
    return(
        <ConfirmationContainer>
            <HeadingContainer>
                <StyledFontAwesomeIcon icon={faCheckCircle} />
                <ConfirmationHeading>Thank you!</ConfirmationHeading>
            </HeadingContainer>
            <StyledParagraph>Finalize your consultation call by scheduling a time that works for you with the link we emailed you. We look forward to speaking with you soon!</StyledParagraph>

        </ConfirmationContainer>
    )
}
export default SubmissionConfirmation;