import styled from "styled-components";
import { MOWUR_LOGO, SSUSA_LOGO } from "../../shared/constants";
const ClientsSection = styled.section`
    @keyframes scroll {
        0% { 
            transform: translateX(0);
        }
        100% { 
            transform: translateX(-50%);
        }
    }
    animation: scroll 20s linear infinite;
    position: relative;
    overflow: hidden;
    margin: auto;
    min-width: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3em;
    @media screen and (min-width:768px){
        animation: scroll 50s linear infinite;
        height: 20vh;
    }
`;
const ClientsSectionContainer = styled.div`
    overflow: hidden;
`;
const StyledClientLogo = styled.img`
    @media screen and (max-width:768px){
        width: 40vw;
    }
    filter: brightness(2) saturate(0%);    
    max-width: 160px;
    position: relative;
    &.desktop-extras{
        display: none;
        @media screen and (min-width: 768px){
            display: unset;
        }
    }
`;

const ClientsCarousel = () => {
    return(
        <ClientsSectionContainer>
            <ClientsSection>
                <StyledClientLogo src={SSUSA_LOGO} alt="SharpShooters logo" />
                <StyledClientLogo src={MOWUR_LOGO} alt="MowUr logo" />
                <StyledClientLogo src={SSUSA_LOGO} alt="SharpShooters logo" />
                <StyledClientLogo src={MOWUR_LOGO} alt="MowUr logo" />

                <StyledClientLogo className="desktop-extras" src={SSUSA_LOGO} alt="SharpShooters logo" />
                <StyledClientLogo className="desktop-extras" src={MOWUR_LOGO} alt="MowUr logo" />
                <StyledClientLogo className="desktop-extras" src={SSUSA_LOGO} alt="SharpShooters logo" />
                <StyledClientLogo className="desktop-extras" src={MOWUR_LOGO} alt="MowUr logo" />
            </ClientsSection>
        </ClientsSectionContainer>
    )
}
export default ClientsCarousel;