import { FC } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const GetStartedContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4em 0em;
    padding: 5em var(--padding-l-r);
    background: linear-gradient(180deg, #111, transparent);
    border-radius: 2em;
    @media and screen (min-width: 768px){
        margin: 4em var(--padding-l-r);
        padding: 5em;
    }
`;
const GetStartedHeading = styled.h2`
    @media (max-width: 768px){
        font-size: 2em;
    }
    font-size: 2.66em;
    text-align: center;
`;
const GetStartedSubheading = styled.p`
    @media (max-width: 768px){
    }
    padding: 1em 0em;
    font-size: 1.1em;
    text-align: center;
    font-weight: 600;
    color: #aaa;
`;

export const GetStartedLink = styled(Link)`
    display: flex;
    padding: 1em 2em;
    margin: 1em 2em;
    text-align: center;
    background-color: white;
    text-decoration: none;
    color: black;
`;

const GetStartedCTA: FC = () => {
    const {t} = useTranslation();
    return(
        <GetStartedContainer>
            <GetStartedHeading>Get started today.</GetStartedHeading>
            <GetStartedSubheading>Take the first step towards your next project today. Start with a free consultation to learn how we can help.</GetStartedSubheading>
            <GetStartedLink
                to="/get-started"
            >
                {t('getStarted')}
            </GetStartedLink>
        </GetStartedContainer>
    )
}
export default GetStartedCTA;