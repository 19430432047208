import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled from 'styled-components';
import { NAVIGATION } from "../../shared/constants";
import NavItemChild from '../NavItemChild';
import { useTranslation } from 'react-i18next';

interface MenuProps{
    isServicesDropdownOpen: boolean;
    toggleServicesDropdown: () => void;
    isCompanyDropdownOpen: boolean;
    toggleCompanyDropdown: () => void;
}

const DesktopNavContainer = styled.div`
    @media (max-width: 768px){
        display: none;
    }
    height: 100%;
    display: flex;
`;
const DropdownMenu = styled.div`
    @media (max-width: 768px){
        display: none;
    }
    position: absolute;
    top: calc(var(--header-height));
    background-color: #eee;
    min-width: 20em;
    z-index: 999;
    padding: 1em;
`;
const NavItemContainer = styled.div`
    position: relative;
`;
const StlyedNavItem = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0em 1em;
`;
const StlyedNavItemButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    font-weight: 500;
`;
const StyledNavItemChevron = styled(FontAwesomeIcon)`
    color: white;
    padding-left: 0.5em;
    cursor: pointer;
    font-size: 0.8em;
`;

const DesktopNav: FC<MenuProps> = ({
    isServicesDropdownOpen, 
    toggleServicesDropdown, 
    isCompanyDropdownOpen, 
    toggleCompanyDropdown
}) => {
    const {t} = useTranslation();
    return (
        <>
            <DesktopNavContainer>
                <NavItemContainer>
                    <StlyedNavItem 
                        onMouseEnter={toggleServicesDropdown} 
                        onClick={toggleServicesDropdown}
                    >
                        <StlyedNavItemButton type='button'>{t('commonServices')}</StlyedNavItemButton>
                        <StyledNavItemChevron icon={isServicesDropdownOpen ? faChevronUp : faChevronDown}/>
                    </StlyedNavItem>
                    {isServicesDropdownOpen && 
                        <DropdownMenu onMouseLeave={toggleServicesDropdown}>
                            {
                                NAVIGATION.map((category:any) => {
                                    return category.LABEL === "Services" && category.CHILDREN.map((navItem: any) =>
                                    <NavItemChild
                                        navItem={navItem}
                                        toggleDropdown={toggleServicesDropdown}
                                    />
                                    )
                                })
                            }
                        </DropdownMenu>
                    }
                </NavItemContainer>

                <NavItemContainer>
                    <StlyedNavItem 
                        onMouseEnter={toggleCompanyDropdown} 
                        onClick={toggleCompanyDropdown}
                    >
                        <StlyedNavItemButton type='button'>{t('commonCompany')}</StlyedNavItemButton>
                        <StyledNavItemChevron icon={isCompanyDropdownOpen ? faChevronUp : faChevronDown}/>
                    </StlyedNavItem>
                    {isCompanyDropdownOpen && 
                        <DropdownMenu onMouseLeave={toggleCompanyDropdown}>
                            {
                                NAVIGATION.map((category:any) => {
                                    return category.LABEL === "Company" && category.CHILDREN.map((navItem: any) =>
                                    <NavItemChild
                                        navItem={navItem}
                                        toggleDropdown={toggleCompanyDropdown}
                                    />
                                    )
                                })
                            }
                        </DropdownMenu>
                    }
                </NavItemContainer>
            </DesktopNavContainer>
        </>
    )
}

export default DesktopNav;