import { 
    faMousePointer, 
    faGlobe, 
    faPerson, 
    faCogs, 
    faDatabase, 
    faMobileAlt, 
    faCloud,
    faBuilding,
    faLayerGroup,
    faCircleNodes,
    faDesktop,
    faCompassDrafting,
    faMobileScreen,
    faPeopleGroup,
    faObjectGroup,
    faClone,
    faFileInvoiceDollar,
    faCloudArrowUp,
    faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons';
export const COMPANY_NAME = "Tudor John & Co.";
export const SELECTED_LANG_KEY = "tudorjohn_selected_language";
export const TUDORJOHN_LOGO = `${process.env.REACT_APP_CDN_SERVICE_URL}/logos/TudorJohnLogo_white.webp`
export const MOWUR_LOGO = `${process.env.REACT_APP_CDN_SERVICE_URL}/client_logos/mowur.webp`
export const SSUSA_LOGO = `${process.env.REACT_APP_CDN_SERVICE_URL}/client_logos/ssusa.webp`


export const NAVIGATION = [
    {
        LABEL: "Services",
        CHILDREN: [
        {
            label: "Mobile",
            labelTranslationString: "common-mobile",
            description: "Mobile applications for iOS and Android",
            descriptionTranslationString: "common-mobile-description",
            path: "/mobile-app-development",
            icon: faMobileScreen
        },
        {
            label: "Web",
            labelTranslationString: "common-web",
            description: "Websites and web applications built for scale",
            descriptionTranslationString: "common-web-description",
            path: "/web-development",
            icon: faGlobe
        },
        {
            label: "Cloud",
            labelTranslationString: "common-cloud",
            description: "Well-architected and highly available cloud solutions",
            descriptionTranslationString: "common-cloud-description",
            path: "/cloud-development",
            icon: faCloud
        },
        {
            label: "Consulting",
            labelTranslationString: "common-consulting",
            description: "Solutions to complex business problems",
            descriptionTranslationString: "common-consulting-description",
            path: "/consulting",
            icon: faPerson
        }]
    },
    {
        LABEL: "Company",
        CHILDREN: [
        {
            label: "About Us",
            labelTranslationString: "common-about-us",
            description: "Discover the magic of how and why we work",
            descriptionTranslationString: "common-company-description",
            path: "/about-us",
            icon: faPeopleGroup
        },
        // {
        //     label: "Process",
        //     labelTranslationString: "common-process",
        //     description: "Websites and web applications built for scale",
        //     descriptionTranslationString: "common-process-description",
        //     path: "/our-process",
        //     icon: faFaceSmile
        // }
        ]
    }

]

export const SOFTWARE_HELP_LIST = [
    {
        heading: "Mobile Apps",
        icon: faMobileAlt,
        description: "Cross-platform mobile apps for iOS and Android"
    },
    {
        heading: "Web Apps",
        icon: faMousePointer,
        description: "Sotware applications deployed on the web"
    },
    {
        heading: "Custom Integrations",
        icon: faCogs,
        description: "Integrate your software with other systems"
    },
    {
        heading: "Data Migration",
        icon: faDatabase,
        description: "Move, transform, and leverage your data"
    },
];
export const MOBILE_HELP_LIST = [
    {
        heading: "Native Apps",
        icon: faMobileAlt,
        description: "Mobile apps developed for native iOS and Android operating systems"
    },
    {
        heading: "Cross Platform Apps",
        icon: faLayerGroup,
        description: "Mobile apps developed for both iOS and Android operating systems"
    },
    {
        heading: "Hybrid Apps",
        icon: faClone,
        description: "Apps developed by leveraging web and mobile technologies"
    },
    {
        heading: "UI & UX Design",
        icon: faObjectGroup,
        description: "Design world-class mobile experience built for scale"
    },
];

export const WEB_HELP_LIST = [
    {
        heading: "Content Management System (CMS)",
        icon: faDesktop,
        description: "Wordpress, Shopify, or Webflow development"
    },
    {
        heading: "UI/UX Design",
        icon: faCompassDrafting,
        description: "Design delightful user experiences"
    },
    {
        heading: "Custom Integrations",
        icon: faCogs,
        description: "Integrate your website with other systems"
    },
    {
        heading: "SEO",
        icon: faMagnifyingGlass,
        description: "Get ranked on the world's most popular search engines"
    },
];

export const CONSULTING_HELP_LIST = [
    {
        heading: "Systems Design",
        icon: faCircleNodes,
        description: "Design systems integrations, software, and data"
    },
    {
        heading: "Software Architecture",
        icon: faBuilding,
        description: "Design or refactor software architecture"
    },
    {
        heading: "Software Migration",
        icon: faLayerGroup,
        description: "Migrate your monolith to microservices"
    },
    {
        heading: "Cloud Cost Optimization",
        icon: faFileInvoiceDollar,
        description: "Reduce and optimize your cloud spend"
    },
];
export const CLOUD_HELP_LIST = [
    {
        heading: "Cloud Cost Optimization",
        icon: faFileInvoiceDollar,
        description: "Reduce and optimize your cloud spend"
    },
    {
        heading: "Architectural Design",
        icon: faBuilding,
        description: "Design or refactor cloud architectures based on best practices and proven solutions"
    },
    {
        heading: "Network Design",
        icon: faCircleNodes,
        description: "Design systems integrations, hybrid networks, and data transfer"
    },
    {
        heading: "Cloud Migration",
        icon: faCloudArrowUp,
        description: "Migrate your on-premises servers and infrastructure to the cloud"
    },
];