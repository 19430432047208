import { FC } from "react";
import styled from "styled-components";

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const StyledSpinner = styled.div`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    animation: spin 1s infinite linear;
    border: solid 3px #ccc;
    width: 20px;
    height: 20px;
    border-radius: 3em;
    border-bottom: solid 3px #333;
`;

const Spinner: FC = () => {
    return(
        <SpinnerContainer>
            <StyledSpinner/>
        </SpinnerContainer>
    )
}
export default Spinner;