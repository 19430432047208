import { FC } from 'react';
import styled from "styled-components";

const StyledLabel = styled.label`
    color: ${props => props.theme.white};
    width: 100%;
    padding: 0.8em 0em;
    font-weight: 600;
`;

interface ContactFormInputLabelProps{
    children: React.ReactNode;
}

const ContactFormInputLabel: FC<ContactFormInputLabelProps> = ({children}) => {
 
    return (
        <StyledLabel>{children}</StyledLabel>       
    )
}
export default ContactFormInputLabel;