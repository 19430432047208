import { FC } from "react";
import styled from "styled-components";
import usePageTitle from "../../hooks/usePageTitle";
import usePageDesciption from "../../hooks/usePageDescription";
import { COMPANY_NAME } from "../../shared/constants";
import GetStartedCTA from "../../Components/GetStartedCTA";

const StyledHeading = styled.h1`
    @media screen and (max-width:768px){
        font-size: 3em;
    }
    overflow-wrap: break-word;
    width: 100%;
`;
const StyledSubheading = styled.p`
    padding-top: 2em;
    font-size: 1.33em;
`;
const StyledCardHeading = styled.h2`
    padding-bottom: 0.66em;
`;
const StyledCardContent = styled.p`
    color: #aaa;
    font-weight: 500;
    max-width: 63vw;
    font-size: 1.66em;
`;
const StyledCard = styled.div`
    background-color: #1a1a1a;
    border-radius: 1em;
    padding: 5em;
`;
const HeroContainer = styled.section`
    @media screen and (max-width:768px){
        background: linear-gradient(330deg, blue 0%, transparent 50%), linear-gradient(30deg, #50ff59, transparent 50%);
    }
    max-height: 80vh;
    min-height: 60vh;
    overflow: hidden;
    padding: 2em 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(350deg, blue 0%, transparent 50%), linear-gradient(10deg, #50ff59, transparent 50%);
`;
const ValuesSection = styled.section`
    min-height: 80vh;
    padding: 4em 7vw;
    display: flex;
    align-items: center;
`;

const AboutUs: FC = () => {
    usePageTitle(`About Us | ${COMPANY_NAME}`);
    usePageDesciption("Get help navigating your most complex problems. We deliver cloud, software, systems, and architecture solutions and help our clients break through limitations.")
    return(
        <>  
            <HeroContainer>
                <StyledHeading>Top software development company</StyledHeading>
                <StyledSubheading>We are a software development and consulting company experienced with delivering world-class web, mobile and cloud solutions.</StyledSubheading>
            </HeroContainer>
            <ValuesSection>
                <StyledCard>
                    <StyledCardHeading>Mindset matters.</StyledCardHeading>
                    <StyledCardContent>We believe that technical excellence, simplicity, attention to detail, and our intense focus on our clients is what sets us apart from the rest.</StyledCardContent>
                </StyledCard>
            </ValuesSection>
            <GetStartedCTA />
        </>
    )
}

export default AboutUs;