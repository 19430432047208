import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Header from './Components/Header';
import Footer from './Components/Footer';
import MobileMenu from './Components/MobileMenu';
import Home from './Pages/Home';
import LanguageDialog from './Components/LanguageDialog';
import { SELECTED_LANG_KEY } from './shared/constants';
import i18n from 'i18next';
import GetStarted from './Pages/GetStarted';
import ScrollToTop from './Components/ScrollToTop';
import NotFound from './Pages/404';
import WebDevelopment from './Pages/WebDevelopment';
import Consulting from './Pages/Consulting';
import ReactGA from 'react-ga4';
import MobileAppDevelopment from './Pages/MobileDevelopment';
import CloudDevelopment from './Pages/CloudDevelopment';
import AboutUs from './Pages/AboutUs';
// import Process from './Pages/Process';
import { Overlay } from './Components/Overlay';

const gaTrackingID = `${process.env.REACT_APP_GA_TRACKING_ID}`;
ReactGA.initialize(gaTrackingID);

const theme = {
  colorPrimary: "blue",
  gray: "gray",
  white: "white",
  black: "black",
  flexCenter: "display: flex; justify-content: center; align-items: center;",
}

function App() {
  const [isLangDialogOpen, setIsLangDialogOpen] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState<boolean>(false);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState<boolean>(false);

  // Track page views in Google Analytics
  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  const selectedLang = localStorage.getItem(SELECTED_LANG_KEY);
  
  useEffect(()=>{
    // If selected language exists in local storage, change language
    if(selectedLang !== null){
      i18n.changeLanguage(selectedLang);
    };
    // document.addEventListener('scroll', () => setIsMenuOpen(false));
    // return () => {
    //   document.removeEventListener('scroll', () => setIsMenuOpen(false));
    // } 
  });

  const toggleMobileMenu = () => setIsMenuOpen(!isMenuOpen);

  const toggleLangDialog = () => {
    if(isServicesDropdownOpen && !isLangDialogOpen){
      toggleServicesDropdown();
    }
    setIsLangDialogOpen(!isLangDialogOpen)
  };

  const toggleServicesDropdown = () => {
    if(isCompanyDropdownOpen){
      toggleCompanyDropdown();
    }
    if(isLangDialogOpen){
      toggleLangDialog()
    }
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
  }
  const toggleCompanyDropdown = () => {
    if(isServicesDropdownOpen){
      toggleServicesDropdown();
    }
    if(isLangDialogOpen){
      toggleLangDialog()
    }
    setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
  }
  const closeMenus = () => {
    isServicesDropdownOpen && toggleServicesDropdown()
    isCompanyDropdownOpen && toggleCompanyDropdown()
    isMenuOpen && toggleMobileMenu()
  }
  return (

    <>
      <ThemeProvider theme={theme}>
        <Router>
          <ScrollToTop />
          <Header 
            toggleMobileMenu={toggleMobileMenu}
            selectedLang={selectedLang} 
            isLangDialogOpen={isLangDialogOpen}
            toggleLangDialog={toggleLangDialog} 
            isServicesDropdownOpen={isServicesDropdownOpen}
            toggleServicesDropdown={toggleServicesDropdown}
            isCompanyDropdownOpen={isCompanyDropdownOpen}
            toggleCompanyDropdown={toggleCompanyDropdown}
          />
          <Overlay open={isCompanyDropdownOpen || isServicesDropdownOpen || isMenuOpen} onClick={closeMenus} id="menu-overlay"/>

          <MobileMenu 
            toggleMenu={toggleMobileMenu} 
            open={isMenuOpen}
          />
          { isLangDialogOpen && 
            <LanguageDialog 
              toggleServicesDropdown={toggleServicesDropdown} 
              toggleCompanyDropdown={toggleCompanyDropdown} 
              toggleLangDialog={toggleLangDialog} 
              open={isLangDialogOpen}/> 
          }
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/get-started' element={<GetStarted />} />
            <Route path='/about-us' element={<AboutUs />} />
            {/* <Route path='/our-process' element={<Process />} /> */}
            <Route path='/web-development' element={<WebDevelopment />} />
            <Route path='/mobile-app-development' element={<MobileAppDevelopment />} />
            <Route path='/cloud-development' element={<CloudDevelopment />} />
            <Route path='/consulting' element={<Consulting />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          {/* {isOffline && <OfflineStatusChange data-cy="offline-notification" message="No internet connection"/>} */}
          <Footer />
        </Router>

      </ThemeProvider>
    </>
  );
}

export default App;
