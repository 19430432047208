import styled from "styled-components"

const Container = styled.div`
    min-height: calc(100vh - var(--header-height));
    background-color: ${props => props.theme.black};
    padding: 5vh var(--padding-l-r);
`;

const PageContainer = ({children}:any) => {
    return(
        <Container>{children}</Container>
    )
}

export default PageContainer;