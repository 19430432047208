import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import TwoColumnLayout from '../../Components/TwoColumnLayout';
import { Link } from 'react-router-dom';
import ClientsCarousel from '../../Components/ClientsCarousel';
import usePageTitle from '../../hooks/usePageTitle';
import { COMPANY_NAME } from '../../shared/constants';
import usePageDesciption from '../../hooks/usePageDescription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCodePullRequest, 
    // faLaptopCode, 
    faUsersViewfinder 
} from '@fortawesome/free-solid-svg-icons';

const HeroContainer = styled.div`
    padding: var(--padding-l-r);
    @media screen and (max-width:768px){
        background: linear-gradient(150deg, rgb(62 0 255), rgba(255, 0, 0, 0) 30.71%), linear-gradient(210deg, rgb(7 157 160), rgba(255, 0, 0, 0) 29.71%);
    }
    background: linear-gradient(160deg, rgb(62 0 255), rgba(255, 0, 0, 0) 30.71%), linear-gradient(190deg, rgb(7 157 160), rgba(255, 0, 0, 0) 29.71%);
    height: calc(100vh - var(--header-height) - 15vh);
    display: flex;
    align-items: center;
`;
const Heading = styled.h1`
    text-align: center;
    padding-bottom: 0.33em;
    @media screen and (min-width:768px){
        text-align: left;
        margin-bottom: 0.66em;
        margin-right: 0.5em;
    }
`;
const Subheading = styled.p`
    color: #aaa;
    font-size: 1.33em;
    font-weight: 500;
    text-align: center;
    padding-bottom: 1em;
    @media screen and (min-width:768px){
        text-align: left;
    }
`;
const SubheadingSection = styled.section`
    display: inline-block;
    flex-direction: column;
    padding-bottom: 2.66em;
    @media screen and (min-width:768px){
        padding-bottom: 2.66em;
    }
`;
const HeroSection = styled.section<any>`
    height: calc(100vh - var(--header-height) - 10vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width:768px){
        flex-direction: row;
        align-items: center;
        padding-top: 0;
        height: calc(100vh - var(--header-height) - 10vh);
    }
`;
const LinkContainer = styled.div`
    @media screen and (max-width:768px){
        width: 100%;
    }   
    min-height: 2em;
    position: relative;
`;

export const StyledLink = styled(Link)`
    @media screen and (max-width:768px){
        width: 100%;
        justify-content: center;
    }
    display: flex;
    padding: 1em 2em;
    text-align: center;
    background-color: white;
    text-decoration: none;
    color: black;
    margin-top: 0.66em;
    @media screen and (min-width:768px){
        margin:0;
        display: inline-block;
    }
`;
const SdlcSection = styled.section`
    padding: 3em 0em;
    // background: yellow;
    border-radius: 2em;
    min-height: min-content;
    text-align: left;
    background: linear-gradient(180deg, #111, transparent);

    @media screen and (min-width: 768px){
        padding: 3.66em;
        margin: 6em var(--padding-l-r);
        margin-top: 8em;
    }
`;
const WorkTypesSectionHeading = styled.h4`
    padding: 0vh var(--padding-l-r);
    margin-bottom: 1.66em;
    @media screen and (min-width: 768px){
        padding: 0;
    }
`;
const WorkTypes = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0vh 7vw;
    @media screen and (min-width: 768px){
        flex-direction: row;
        margin: 0;
    }
`;
const WorkTypeContainer = styled.div`
    margin-bottom: 1em;
    padding: 2em 1.33em;
    border-radius: 2em;
    background-color: #161616;
    border: solid 2px slateblue;

    @media screen and (min-width: 768px){
        padding: 2em;
        max-width: 46.66%;
    }
`;
const WorkTypeHeading = styled.h5`
    margin: 1em 0em;
`;
const WorkTypeDescription = styled.p`
    color: #aaa;
    font-size: 1.1em;
    font-weight: 600;
`;
const IconContainer = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4.66em;
    height: 4.66em;
    border-radius: 4.66em;
    background: linear-gradient(30deg, #1800ff, transparent 45%), 
        linear-gradient(150deg, #4848ff, transparent 45%),
        linear-gradient(210deg, #da00ff, transparent 45%),
        linear-gradient(330deg, #006680, transparent 45%);
    @media screen and (min-width: 768px){
        width: 5em;
        height: 5em;
        border-radius: 5em;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 1.66em;
    color: white;
    @media screen and (min-width: 768px){
        font-size: 2em;
    }
`;

const Home: FC = () => {
    const {t} = useTranslation();
    usePageTitle(`Software, Web, and Blockchain Development | ${COMPANY_NAME}`);
    usePageDesciption("Get software, web, or blockchain development services today. We help businesses reach new heights by leveraging technology.");
    return (
        <>
            <HeroContainer>
                <HeroSection>
                    <Heading>{t("heroHeading")}</Heading>
                    <SubheadingSection>
                        <Subheading>{t("heroSubheading")}</Subheading>
                        <LinkContainer>
                            <StyledLink
                                to="/get-started"
                            >
                                {t('getStarted')}
                            </StyledLink>
                        </LinkContainer>
                    </SubheadingSection>
                </HeroSection>
            </HeroContainer>
            <ClientsCarousel />
            <TwoColumnLayout 
                path="/mobile-app-development"
                contentPosition="right"
                heading="Cloud native mobile apps"
                textContent={t('homePageSoftwareSectionHeading')} 
                bgImage={`${process.env.REACT_APP_CDN_SERVICE_URL}/images/mountain_fullsize.webp`}
                linkText={t('learnMore')}
            />
            <TwoColumnLayout 
                path="/web-development"
                contentPosition="left"
                heading="Websites and web apps that scale"
                textContent={t('homePageWebSectionHeading')} 
                bgImage={`${process.env.REACT_APP_CDN_SERVICE_URL}/images/skyline_fullsize.webp`}
                linkText={t('learnMore')}
            />
            <TwoColumnLayout 
                path="/cloud-development"
                contentPosition="right"
                heading="Custom cloud solutions for leading cloud providers"
                textContent={t('homePageCloudSectionHeading')} 
                bgImage={`${process.env.REACT_APP_CDN_SERVICE_URL}/images/dome_fullsize.webp`}
                linkText={t('learnMore')}
            />
            <TwoColumnLayout 
                path="/consulting"
                contentPosition="left"
                heading="Break through limitations"
                textContent={t('homePageConsultingSectionHeading')} 
                bgImage={`${process.env.REACT_APP_CDN_SERVICE_URL}/images/comet_fullsize.webp`}
                linkText={t('learnMore')}
            />
            <SdlcSection>
                <WorkTypesSectionHeading>Work with us, how it works for you</WorkTypesSectionHeading>
                <WorkTypes>
                    <WorkTypeContainer>
                        <IconContainer>
                            <Icon icon={faUsersViewfinder}/>
                        </IconContainer>
                        <WorkTypeHeading>Dedicated developers or teams</WorkTypeHeading>
                        <WorkTypeDescription>Augment your internal bandwidth with dedicated developers or teams. If you need extra hands on deck without the overhead and time required to hire, this is the perfect solution for your organization.</WorkTypeDescription><br />
                    </WorkTypeContainer>
                    <WorkTypeContainer>
                        <IconContainer>
                            <Icon icon={faCodePullRequest}/>
                        </IconContainer>
                        <WorkTypeHeading>Outsource your project</WorkTypeHeading>
                        <WorkTypeDescription>Leverage our teams to deliver what your organization needs. We handle the entire software development lifecycle from design through deployment and beyond.</WorkTypeDescription>
                    </WorkTypeContainer>
                </WorkTypes>
            </SdlcSection>
        </>
    )
}

export default Home;