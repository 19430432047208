import { FC } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ListSectionContainer = styled.div`
    padding: 3.5em 0em;
    display: inline-block;
    overflow: hidden;
    background: #121212;
    width: 100vw;
`;
const ScrollContainer = styled.div`
    @media screen and (max-width:768px){
        overflow-x: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    &::-webkit-scrollbar{
        display: none;
    }
    padding: 1em 0em;
    display: inline-grid;
`;
const ListSection = styled.div`
    @media screen and (max-width:768px){
        overflow-x: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    padding: 1em 0em;
    display: flex;
    padding: 0vh var(--padding-l-r);
`;
const ListHeading = styled.h3`
    color: #ccc;
    padding 0vh var(--padding-l-r);
`;
const ListHeadingGradient = styled.span`
    background: linear-gradient(45deg, #4284fb 50%, violet);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const ListItem = styled.div`
    @media screen and (max-width:768px){
        width: 66vw;
        height: 100%;
    }
    width: 20vw;
    border-radius: 1em;
    padding: 2em;
    margin-right: 1em;
    flex: 0 0 auto;
    background: #222;
`;
const ListItemHeading = styled.h6`
    color: white;
    margin-bottom: 0.5em;
`;
const ListItemDescription = styled.p`
    color: white;
`;
const IconContainer = styled.div`
    background: #4848ff;
    width: 5em;
    height: 5em;
    border-radius: 5em;
    margin-bottom: 0.75em;
    display: inline-flex;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    font-size: 2em;
    color: white;
    padding: 0.66em;
`;

interface ScrollableListProps{
    list: any[];
}

const ScrollableList: FC<ScrollableListProps> = ({list}) => {
    // const {t} = useTranslation();
    return(
        <ListSectionContainer>
            <ListHeading>A few ways <ListHeadingGradient>we can help</ListHeadingGradient></ListHeading>
            <ScrollContainer>
                <ListSection>
                    {list.map(({heading, description, icon}) => 
                        <ListItem key={heading}>
                            <IconContainer>
                                <StyledFontAwesomeIcon icon={icon} />
                            </IconContainer>
                            <ListItemHeading>{heading}</ListItemHeading>
                            <ListItemDescription>{description}</ListItemDescription>
                        </ListItem>
                    )}
                </ListSection>
            </ScrollContainer>
        </ListSectionContainer>
    )
}

export default ScrollableList;