import { FC } from 'react';
import ContactForm from '../../Components/ContactForm';
import usePageTitle from '../../hooks/usePageTitle';
import usePageDesciption from '../../hooks/usePageDescription';
import { COMPANY_NAME } from '../../shared/constants';

const GetStarted: FC = () => {
    usePageTitle(`Free Consultation | ${COMPANY_NAME}`);
    usePageDesciption("Get a free consultation to learn how we can help with your web or software development needs. We help businesses reach new height by leveraging technology.");

    return <ContactForm/>
}

export default GetStarted;