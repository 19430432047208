import styled from 'styled-components';

interface OverlayProps{
    open: boolean;
}

export const Overlay = styled.div<OverlayProps>`
    position: absolute;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    backdrop-filter: blur(2px);
    z-index: 9;
    visibility: ${({open}) => open ? 'unset' : 'hidden'};
    width: 100vw;
    max-width: 100%;
    height: 100%;
    @media (min-width: 768px){
        max-width: 100vw;
    }
`;
