import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledArrowLink = styled(Link)`
    @media screen and (max-width:768px){
        display: flex;
        justify-content: center;
    }
    font-weight: 600;
    color: white;
    padding: 0.8rem 6rem;
    border: solid 2px white;
`;

interface ArrowLinkProps{
    path: string;
    children: any;
}

const ArrowLink = ({path, children}: ArrowLinkProps) => {
    return(
        <StyledArrowLink to={path}>
            {children}
        </StyledArrowLink>
    )
}
export default ArrowLink;