import { FC } from "react";
import styled from "styled-components";
import usePageTitle from "../../hooks/usePageTitle";
import { MOBILE_HELP_LIST } from "../../shared/constants";
import usePageDesciption from "../../hooks/usePageDescription";
import { useTranslation } from "react-i18next";
import ScrollableList from "../../Components/ScollableList";
import GetStartedCTA from "../../Components/GetStartedCTA"

const HeroContainer = styled.div`
    max-height: 80vh;
    min-height: 60vh;
    overflow: hidden;
    padding: 2em 7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const StyledHeading = styled.h1`
    padding-bottom: 0.33em;
    overflow-wrap: break-word;
    width: 100%;

`;
const StyledSubheading = styled.p`
    font-size: 1.33em;
    font-weight: 600;
    color: #aaa;
    min-width: 100%;
`;


const MobileAppDevelopment: FC = () => {
    const {t} = useTranslation();
    usePageTitle(`Mobile App Development Service`);
    usePageDesciption("Get cross-platform or native mobile apps developed. Cloud native mobile apps for iOS and Android, hybrid apps, and more.");
    return(
        <>
            <HeroContainer>
                <StyledHeading>{t("mobilePageHeading")}</StyledHeading>
                <StyledSubheading>Reach your customers where they are with a mobile app designed to deliver a seamless experience.</StyledSubheading>
            </HeroContainer>
            <ScrollableList
                list={MOBILE_HELP_LIST}
            />
            <GetStartedCTA />
        </>
    )
}

export default MobileAppDevelopment;