import styled from "styled-components";

const LogosSection = styled.section`
    @keyframes scroll {
        0% { 
            transform: translateX(0);
        }
        100% { 
            transform: translateX(-50%);
        }
    }
    animation: scroll 25s linear infinite;
    position: relative;
    overflow: hidden;
    margin: auto;
    width: 600%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3em;
    @media screen and (min-width:768px){
        animation: scroll 30s linear infinite;
        height: 20vh;
        width: 250%;
    }
`;
const CarouselContainer = styled.div`
    overflow: hidden;
    padding: 3rem 0rem;
`;
const StyledLogo = styled.img`
    @media screen and (max-width:768px){
        max-width: 40vw;
        max-height: 70px
    }
    filter: brightness(2) saturate(0%) contrast(0.5);    
    max-width: 160px;
    max-height: 80px;
    position: relative;
`;

const WebTechnologiesCarousel = () => {
    return(
        <CarouselContainer>
            <LogosSection>
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/angular.webp`} alt="Angular logo" />
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/react.webp`} alt="React logo" />
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/shopify.webp`} alt="Shopify logo" />
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/drupal.webp`} alt="Drupal logo" />
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/webflow.webp`} alt="Webflow logo" />
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/wordpress.webp`} alt="Wordpress logo" />
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/vue.webp`} alt="Vue logo" />
                <StyledLogo src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/nextjs.webp`} alt="NextJS logo" />

                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/angular.webp`} alt="Angular logo" />
                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/react.webp`} alt="React logo" />
                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/shopify.webp`} alt="Shopify logo" />
                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/drupal.webp`} alt="Drupal logo" />
                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/webflow.webp`} alt="Webflow logo" />
                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/wordpress.webp`} alt="Wordpress logo" />
                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/vue.webp`} alt="Vue logo" />
                <StyledLogo className="desktop-extras" src={`${process.env.REACT_APP_CDN_SERVICE_URL}/web_technology_logos/nextjs.webp`} alt="NextJS logo" />
            </LogosSection>
        </CarouselContainer>
    )
}
export default WebTechnologiesCarousel;