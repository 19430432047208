import styled from 'styled-components';
import ArrowLink from '../ArrowLink';
// import { useTranslation } from 'react-i18next';

interface ColumnProps{
    bgImage?: string;
    contentPosition?: string;
}
interface ContentContainerProps{
    contentPosition?: string;
}
interface TwoColumnLayoutProps{
    bgImage?: string;
    heading: string;
    textContent?: string;
    contentPosition?: string;
    path: string;
    linkText: string;
}
const Column = styled.div<ColumnProps>`
    @media screen and (max-width: 768px){
        width: 100vw;
        max-width: 100vw;
        flex-direction: column;
        margin: 0;
        height: 60vh;
    }
    width: 50vw;
    height: 90vh;
    background-image: url(${({bgImage}) => bgImage});
    background-position: bottom;
    background-size: cover;
    ${({contentPosition}) => contentPosition === "left" ? "margin-left: 50vw;" : "margin-right: 50vw;"}
`;
const TwoColumnContainer = styled.section`
    @media screen and (max-width: 768px){
        width: 100vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: black;
    position: relative;
`
const StyledTextContent = styled.p`
    font-size: 1.1em;
    color: #aaa;
    margin-bottom: 2.5em;
    overflow-wrap: break-word;
    font-weight: 600;
`;
const StyledHeading = styled.h2`
    color: white;
    margin-bottom: 0.66em;
    margin-right: 2em;
    overflow-wrap: break-word;
    font-weight: 500;
    @media screen and (min-width: 768px){
    }
`;
const ContentContainer = styled.section<ContentContainerProps>`
    @media screen and (max-width: 768px){
        padding: 5em 7vw;  
        width: 100%;      
    }
    @media screen and (min-width: 768px){
        max-width: 36vw;
        position: absolute;
        margin-right: 7vw;
        ${({contentPosition}) => contentPosition === "left" ? "left: 7vw;" : "left: 57vw;"} 
    }
`;

const TwoColumnLayout = ({bgImage, heading, textContent, contentPosition, path, linkText}: TwoColumnLayoutProps) => {
    // const {t} = useTranslation();
    return(
        <TwoColumnContainer>        
            <Column bgImage={bgImage} contentPosition={contentPosition}/>
            <ContentContainer contentPosition={contentPosition}>
                <StyledHeading>{heading}</StyledHeading>
                <StyledTextContent>{textContent}</StyledTextContent>
                <ArrowLink path={path}>{linkText}</ArrowLink>
            </ContentContainer>
        </TwoColumnContainer>
    )
}
export default TwoColumnLayout;