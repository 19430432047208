import { FC, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NAVIGATION } from '../../shared/constants';
import TudorJohnLogo from '../../TudorJohnLogo_white.png';
import { useTranslation } from 'react-i18next';
import NavItemChild from '../NavItemChild';
interface StyledMenuProps{
    open: boolean;
}

const StyledMenu = styled.div<StyledMenuProps>`
    @media (max-width: 600px){
        min-width: 100vw;  
    }
    @media (min-width: 768px){
        display: none;
    }
    display: ${({open}) => open ? 'flex' : 'none'};
    flex-direction: column;
    height: calc(100vh - var(--header-height));
    height: -webkit-fill-available;
    width: 270px;
    transform:  ${({open}) => open ? 'translateX(0%)' : 'translateX(200%)'};
    transition: transform 0.2s ease-in-out;
    max-width: 80vw;
    position: fixed;
    right: 0;
    top: 0;
    background-color: ${({theme}) => theme.black};
    color: ${({theme}) => theme.white};
    z-index: 10;
    overflow: hidden;
`;
const MenuHeader = styled.div`
    height: var(--header-height);
    display: flex;
    align-items: center;
`;
const Logo = styled.img`
    @media (max-width: 600px){
        display: unset;   
    }
    position: absolute;
    left: 7vw;
    display: none;
    max-width: 175px;
    margin-right: 2.66em;
`;
const CloseMenuButton = styled.button`
    @media (max-width: 600px){
        right: 7vw;  
    }
    background: transparent;
    border: none;
    position: absolute;
    &:focus{
        outline: solid 2px white;
    }
`;
const CloseMenuIcon = styled(FontAwesomeIcon)`
    color: ${({theme}) => theme.gray};
    font-size: 1.2em;
    cursor: pointer;
`;
const MenuNav = styled.nav`
    padding: 1em 2em 0em 2em;
    display: flex;
    width: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar{
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;
const MenuNavList = styled.ul`
    list-style-type: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - var(--header-height) - 11em);
    overflow-y: scroll;
    padding-bottom: 20vh;
    &::-webkit-scrollbar{
        display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;
const MenuNavListItem = styled.li`
    border-bottom: solid 1px #333;
    display: flex;
    width: 100%;
    align-items: center;
`;
const MenuNavDropdown = styled.span`
    @media (max-width: 600px){
        justify-content: left;
        font-size: 1em;
    }
    color: ${({theme}) => theme.white};
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: left;
    text-transform: uppercase;
    font-size: 1.15rem;
    font-weight: 600;
    padding: 2em 0em;
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${({theme}) => theme.gray};
    font-size: 1.1em;
    cursor: pointer;
`;
const ServicesDropdown = styled.section`
    width: 100%;
    margin: 2em 0em 0em 0em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
`;
const GetStartedLinkContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0em 2em;
    position: fixed;
    bottom: 0;
    border-top: solid 1px #202020;
    background-color: black;
`;
const GetStartedLink = styled(Link)`
    letter-spacing: -0.033em;
    color: black;
    background-color: white;
    display: flex;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1em 0em;
    margin: 2em 0em;
`;

interface MenuProps{
    toggleMenu: () => void;
    open: boolean;
}

const MobileMenu: FC<MenuProps> = ({toggleMenu, open}) => {
    const {t} = useTranslation();

    const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState<boolean>(false);
    const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState<boolean>(false);
    const toggleServicesDropdown = () => setIsServicesDropdownOpen(!isServicesDropdownOpen);
    const toggleCompanyDropdown = () =>  setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
    
    return (
        <>
            <StyledMenu id="menu" open={open}>
                <MenuHeader>
                    <Logo src={TudorJohnLogo}/>
                    <CloseMenuButton onClick={toggleMenu}>
                        <CloseMenuIcon icon={faTimes}/>
                    </CloseMenuButton>
                </MenuHeader>
                <MenuNav>
                    <MenuNavList>
                        <MenuNavListItem onClick={toggleServicesDropdown}>
                            <MenuNavDropdown>{t('commonServices')}</MenuNavDropdown>
                            <StyledFontAwesomeIcon icon={isServicesDropdownOpen ? faChevronUp : faChevronDown}/>
                        </MenuNavListItem>
                        {isServicesDropdownOpen && 
                            <ServicesDropdown>
                                {  NAVIGATION.map((category:any) => {
                                    return category.LABEL === "Services" && category.CHILDREN.map((navItem: any) =>
                                        <NavItemChild 
                                            key={navItem.label}
                                            navItem={navItem}
                                            toggleDropdown={toggleServicesDropdown} 
                                            toggleMenu={toggleMenu}
                                        />
                                    )
                                })}
                            </ServicesDropdown>
                        }

                        <MenuNavListItem onClick={toggleCompanyDropdown}>
                            <MenuNavDropdown>{t('commonCompany')}</MenuNavDropdown>
                            <StyledFontAwesomeIcon icon={isCompanyDropdownOpen ? faChevronUp : faChevronDown}/>
                        </MenuNavListItem>
                        {isCompanyDropdownOpen && 
                            <ServicesDropdown>
                                {  NAVIGATION.map((category:any) => {
                                    return category.LABEL === "Company" && category.CHILDREN.map((navItem: any) =>
                                        <NavItemChild 
                                            key={navItem.label}
                                            navItem={navItem}
                                            toggleDropdown={toggleCompanyDropdown} 
                                            toggleMenu={toggleMenu}
                                        />
                                    )
                                })}
                            </ServicesDropdown>
                        }
                    </MenuNavList>
                </MenuNav>
                <GetStartedLinkContainer onClick={toggleMenu}>
                            <GetStartedLink to="/get-started">{t('getStarted')}</GetStartedLink>
                </GetStartedLinkContainer>
            </StyledMenu>
        </>
    )
}

export default MobileMenu;