import styled from "styled-components"

const Container = styled.div`
    padding-left: var(--padding-l-r);
    padding-right: var(--padding-l-r);
`;

const ContentContainer = ({children}:any) => {
    return(
        <Container>{children}</Container>
    )
}

export default ContentContainer;