import { FC } from 'react';
import styled from 'styled-components';

const StyledCheckboxContainer = styled.section`
    width: 100%;
    padding: 0.33em 0em;
`;
const StyledCheckbox = styled.input`
`;
const StyledCheckboxLabel = styled.label`
    width: 100%;
    padding: 0.8em 0em;
    margin-left: 0.66em;
    font-weight: 500;
    color: white;
`;

interface ContactFormCheckboxProps{
    label: string;
    name: string;
    type: string;
    value: string;
    changeHandler: (e: any) => void;
}

const ContactFormCheckbox: FC<ContactFormCheckboxProps> = ({ value, label, name, type, changeHandler}) => {
 
    return (
        <StyledCheckboxContainer>
            <StyledCheckbox 
                name={name} 
                type={type}
                value={value}
                onChange={changeHandler}
            ></StyledCheckbox>
            <StyledCheckboxLabel>{label}</StyledCheckboxLabel>
        </StyledCheckboxContainer>        
    )
}
export default ContactFormCheckbox;